/**
 * Renders a Directory of given items and categories
 *
 * @flow
 */
import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
// Note: @reach/router is a gatsby dependency
// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate, globalHistory } from '@reach/router';
import elasticlunr from 'elasticlunr';

import { paths } from '../../../config';
import type { Meta } from '../../types';
import { Container, IntroText } from '../../styles';
import Layout from '../../layouts/DefaultLayout';
import SEO from '../SEO';
import PageHeaderWithAddButton from '../PageHeaderWithAddButton';
import type { DirectoryItemObj, CategoryObj, SearchResultsObj } from './types';

import CategoriesList from './CategoriesList';
import SearchResults from './SearchResults';
import TextField from '../TextField';
import { ActiveCategoryContext } from './withActiveCategory';
import ItemSubmissionForm from '../ItemSubmissionForm';
import FileUploadField from '../FileUploadField';
import Link from '../Link';
import Modal from '../Modal';
import Select from '../Select';
import useSubmissionModal from '../../hooks/useSubmissionModal';

const StyledPageHeader = styled(PageHeaderWithAddButton)`
  margin-top: 0;
`;

const StyledTextField = styled(TextField)`
    font-size: inherit;
    margin-top: 0;

    input {
      width: 100%;
      max-width: 250px;
      padding-bottom: 0;
      font-size: inherit;
    }
`;

const SearchWrapper = styled.div`
  display: flex;
  margin-bottom: 45px;
  align-items: center;
  color: #8e8e8e;
  font-size: 17px;

  label {
    margin-right: 10px;
    white-space: nowrap;
  }

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const ContentWrapper = styled.div`
  h2,
  .h2 {
    margin-bottom: 0.5em;
  }
`;

const StyledImg = styled(Img)`
  width: auto;
  max-width: 582px;
`;

const StyledSelect = styled(Select)`
  max-width: 368px;
`;

const StyledModal = styled(Modal)`
  .h1 {
    text-align: center;
  }
`;

const ModalIntroText = styled(IntroText)`
  text-align: center;
`;

function getOrderValue(obj) {
  return obj.acf && obj.acf.order
    ? obj.acf.order
    : 0;
}

function normalizeItems(items) {
  return items.edges
    .map(({ node: { categories, tags, ...rest } }) => ({
      ...rest,
      categories: categories ? categories.map((cat) => cat.slug) : [],
      tags: tags ? tags.map((tag) => tag.name) : [],
    }))
    // filter out items without website and featured image
    .filter((r) => r.acf && r.acf.website && r.featuredImage && r.featuredImage.localFile)
    .sort((a, b) => getOrderValue(b) - getOrderValue(a));
}

function normalizeCategories(categories, items: DirectoryItemObj[]) {
  return categories.edges
    .map(({ node: { acf, ...restOfCat } }) => {
      const { tags, ...restOfAcf } = acf || {};
      return {
        ...restOfCat,
        items: items.filter((item) => item.categories && item.categories.includes(restOfCat.slug)),
        acf: {
          ...restOfAcf,
          tags: tags ? tags.map((tag) => tag.name) : [],
        },
      };
    })
    // graphql sort functionality puts items with null order value on the top
    // therefore we are sorting here again
    .sort((a, b) => getOrderValue(b) - getOrderValue(a));
}

function arrayToObjMap(arr: any[]) {
  return arr.reduce((obj, item) => {
    // The reduce...spread anti-pattern
    // @link https://www.richsnapp.com/blog/2019/06-09-reduce-spread-anti-pattern
    // eslint-disable-next-line no-param-reassign
    obj[item.id] = item;
    return obj;
  }, {});
}

function getSearchQuery(search) {
  const searchParams = new URLSearchParams(search);
  return searchParams.get('q');
}

export type Props = {
  name: string,
  singularName: string,
  path: string,
  data: {
    page: {
      title: string,
      yoastMeta: Meta[],
      customMeta: {
        title: string,
        canonical: string,
      },
      acf: {
        introText: string,
      },
    },
    allItems: {
      edges: Array<{
        node: {
          ...$Exact<DirectoryItemObj>,
          categories?: Array<{
            slug: string,
          }>,
          tags?: Array<{
            name: string,
          }>,
          // featuredImage may be null at this point
          featuredImage?: $PropertyType<DirectoryItemObj, 'featuredImage'>,
        },
      }>,
    },
    allCategories: {
      edges: Array<{
        node: {
          ...$Exact<CategoryObj>,
          acf: {
            ...$Exact<$PropertyType<CategoryObj, 'acf'>>,
            tags?: Array<{
              name: string,
            }>,
          }
        },
      }>,
    },
  },
};

type SearchIndex = {
  search: (query: string, options?: any) => Array<{ ref: string, doc: any, score: number }>,
};

const Directory = ({
  name,
  singularName,
  path,
  data: {
    page,
    allItems,
    allCategories,
  },
}: Props) => {
  const [categories, setCategories] = useState<CategoryObj[]>([]);
  const [itemsMap, setItemsMap] = useState<{ [id: string]: DirectoryItemObj }>({});
  const [categoriesMap, setCategoriesMap] = useState<{ [id: string]: CategoryObj }>({});
  const [searchIndex, setSearchIndex] = useState<?SearchIndex>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activeCategory, setActiveCategory] = useState<string>('');
  const [searchResults, setSearchResults] = useState<?SearchResultsObj>();

  const [
    submissionModalOpen,
    submissionFiles,
    submissionModalImage,
    toggleSubmissionModal,
    handleSubmissionFileChange,
  ] = useSubmissionModal();

  const updateSearchResults = () => {
    const results = searchIndex ? searchIndex.search(searchTerm, {
      fields: {
        title: { boost: 4 },
        tags: { boost: 3 },
        description: { boost: 2 },
        categories: { boost: 1 },
      },
      expand: true,
    }) : [];

    const itemResults = [];
    const categoryResults = [];

    results.forEach((result) => {
      if (itemsMap[result.ref]) {
        itemResults.push(itemsMap[result.ref]);
      } else if (categoriesMap[result.ref]) {
        categoryResults.push(categoriesMap[result.ref]);
      }
    });

    setSearchResults({
      items: itemResults,
      categories: categoryResults,
    });
  };

  useEffect(() => {
    const searchQuery = getSearchQuery(window.location.search);
    if (searchQuery) {
      setSearchTerm(searchQuery);
    }

    const normalizedItems: DirectoryItemObj[] = normalizeItems(allItems);
    setItemsMap(arrayToObjMap(normalizedItems));

    const normalizedCategories: CategoryObj[] = normalizeCategories(
      allCategories,
      normalizedItems,
    );
    setCategories(normalizedCategories);
    setCategoriesMap(arrayToObjMap(normalizedCategories));

    const index = elasticlunr();
    index.setRef('id');
    index.addField('title');
    index.addField('description');
    index.addField('categories');
    index.addField('tags');

    normalizedItems.forEach(({
      id, title, acf, categories: itemCategories, tags,
    }) => {
      index.addDoc({
        id,
        title,
        description: acf && acf.description ? acf.description : '',
        categories: itemCategories && itemCategories.join(' '),
        tags: tags ? tags.join(' ') : '',
      });
    });

    normalizedCategories.forEach(({
      id, name: title, description, acf,
    }) => {
      index.addDoc({
        id,
        title,
        description,
        categories: '',
        tags: acf && acf.tags ? acf.tags.join(' ') : '',
      });
    });

    setSearchIndex(index);
  }, []);

  useEffect(() => {
    updateSearchResults();
  }, [searchIndex, searchTerm]);

  useEffect(() => globalHistory.listen(() => {
    const searchQuery = getSearchQuery(window.location.search);
    if (searchQuery !== searchTerm) {
      setSearchTerm(searchQuery || '');
      setActiveCategory('');
    }
  }), [searchTerm]);

  const handleSearchChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const newSearchTerm = event.currentTarget.value;
    setSearchTerm(newSearchTerm);
    setActiveCategory('');
    navigate(`${path}/?q=${encodeURIComponent(newSearchTerm)}`);
  };

  const categoryOptions = useMemo(
    () => categories.map(({ name: catName }) => ({ value: catName, label: catName })),
    [categories],
  );

  return (
    <Layout fullwidth backgroundColor="#e7f3fb">
      <SEO
        title={page.customMeta.title}
        canonical={page.customMeta.canonical}
        meta={page.yoastMeta}
      />
      <Container>
        <div className="row">
          <div className="offset-lg-2">
            <StyledPageHeader
              title={page.title}
              introText={page.acf.introText}
              addBtnTextSuffix={`a ${singularName}`}
              onAddBtnClick={toggleSubmissionModal}
            />
            <SearchWrapper>
              {/* False positive: not identifying id passed to the styled component */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="directory-search-field">Search For</label>
              <StyledTextField
                id="directory-search-field"
                value={searchTerm}
                type="search"
                onChange={handleSearchChange}
              />
            </SearchWrapper>
          </div>
        </div>
        <ActiveCategoryContext.Provider
          value={{
            activeCategory,
            setActiveCategory,
          }}
        >
          <ContentWrapper>
            {searchTerm && searchResults && (
              <SearchResults directoryName={name} searchResults={searchResults} />
            )}
            {!searchTerm && (
              <>
                <h2>Categories</h2>
                <CategoriesList categories={categories} />
              </>
            )}
          </ContentWrapper>
        </ActiveCategoryContext.Provider>
      </Container>
      <StyledModal
        open={submissionModalOpen}
        title={`Add ${singularName}`}
        headerImage={<StyledImg fluid={submissionModalImage.childImageSharp.fluid} />}
        headerBackgroundColor="rgba(156, 208, 185, 0.104)"
        onClose={toggleSubmissionModal}
      >
        <ModalIntroText>
          Share your favorite
          {' '}
          <Link
            to={paths.faq}
            target="_blank"
            rel="noopener noreferrer"
          >
            ethical
          </Link>
          {' '}
          resource which you recently discovered or use regularly.
        </ModalIntroText>
        <ItemSubmissionForm submitLabel={`Submit ${singularName}`} files={submissionFiles}>
          <input type="hidden" name="_subject" value={`New ${singularName.toLowerCase()} submission!`} />
          <TextField name="Resource" label="Resource Name" required />
          <StyledSelect name="Category" label="Category" options={categoryOptions} isMulti allowCreate isSearchable />
          <TextField name="Website" label="Website URL" type="url" required />
          <TextField name="Description" label="Description" multiline minLength={280} required />
          <FileUploadField
            accept="image/*"
            label="Logo"
            labelHidden
            helpText="Add logo"
            maxFiles={5} // limitation by Formspree
            onChange={handleSubmissionFileChange}
          />
        </ItemSubmissionForm>
      </StyledModal>
    </Layout>
  );
};

export const featuredImageFragment = graphql`
  fragment FeaturedImage on wordpress__wp_media {
    alt: alt_text
    localFile {
      childImageSharp {
        fluid(maxWidth: 248) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const directoryPageFragment = graphql`
  fragment DirectoryPage on wordpress__PAGE {
    title
    yoastMeta: yoast_meta {
      name
      property
      content
    }
    customMeta: et_custom_meta {
      title
      canonical
    }
    acf {
      introText: intro_text
    }
  }
`;

export default Directory;
