/**
 * Renders a list/grid of directory items
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';

import type { DirectoryItemObj } from './types';
import DirectoryListItem from './DirectoryListItem';
import Column from './Column';

const Wrapper = styled.div`
  --vertical-margin: 17px;
  margin-top: calc(-1 * var(--vertical-margin));
  margin-bottom: calc(-1 * var(--vertical-margin));

  @media (min-width: 768px) {
    --vertical-margin: 28px;
  }

  @media (min-width: 992px) {
    --vertical-margin: 30px;
  }
`;

type Props = {
  items: DirectoryItemObj[],
};

const DirectoryList = ({ items }: Props) => (
  <Wrapper className="row">
    {items.map((item) => (
      <Column key={item.id}>
        <DirectoryListItem item={item} />
      </Column>
    ))}
  </Wrapper>
);

export default DirectoryList;
