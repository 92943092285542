/**
 * Renders a single directory item
 *
 * @flow
 */
import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import type { DirectoryItemObj } from './types';
import ExternalIcon from '../../images/external.svg';
import Link from '../Link';

const Wrapper = styled.div`
  margin-top: var(--vertical-margin, 17px);
  margin-bottom: var(--vertical-margin, 17px);
`;

const StyledLink = styled(Link)``;

const StyledImg = styled(Img)`
  margin-bottom: 11px;
  border-radius: 4px;

  * {
    vertical-align: bottom; /* removes extra space from image bottom */
  }
`;

const Title = styled.h4`
  span:first-child {
    vertical-align: middle;
  }
`;

const ExternalIconWrapper = styled.span`
  margin-left: 0.4em;
  color: #a5c8f1;
`;

const Description = styled.div`
  font-size: 15px;
  color: #8e8e8e;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

type Props = {
  item: DirectoryItemObj,
  className?: string,
};

const defaultProps = {
  className: '',
};

const DirectoryListItem = ({
  item: {
    title,
    acf: {
      description,
      website,
    } = {},
    featuredImage,
  },
  className,
}: Props) => (
  <Wrapper className={className}>
    <StyledLink to={website} target="_blank" rel="noopener noreferrer">
      {featuredImage && featuredImage.localFile && (
        <StyledImg
          fluid={{ ...featuredImage.localFile.childImageSharp.fluid, aspectRatio: 1 }}
          alt={featuredImage.alt}
        />
      )}
      <Title>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: title }} />
        <ExternalIconWrapper>
          <ExternalIcon />
        </ExternalIconWrapper>
      </Title>
      {/* eslint-disable-next-line react/no-danger */}
      <Description dangerouslySetInnerHTML={{ __html: description }} />
    </StyledLink>
  </Wrapper>
);

DirectoryListItem.defaultProps = defaultProps;

export default DirectoryListItem;
