// @flow
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { paths } from '../../config';
import Directory from '../components/Directory';

function Shops() {
  const data = useStaticQuery(graphql`
    query {
      page: wordpressPage(path: {eq: "/shops/"}) {
        ...DirectoryPage
      }
      allItems: allWordpressWpShops(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id: wordpress_id
            title
            categories: shop_categories {
              slug
            }
            tags: shop_tags {
              name
            }
            acf {
              order: display_order
              description: shop_description
              website: shop_website_url
            }
            featuredImage: featured_media {
              ...FeaturedImage
            }
          }
        }
      }
      allCategories: allWordpressWpShopCategories(sort: {fields: acf___display_order, order: DESC}, filter: {count: {gt: 0}}) {
        edges {
          node {
            id: wordpress_id
            name
            slug
            description
            acf {
              order: display_order
              featuredImage: featured_image {
                ...FeaturedImage
              }
              tags {
                name
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Directory
      name="Shops"
      singularName="Shop"
      path={paths.shops}
      data={data}
    />
  );
}

export default Shops;
