/**
 * Renders a list/grid of Categories
 *
 * @flow
 */
import React from 'react';

import type { CategoryObj } from './types';
import CategoryListItem from './CategoryListItem';
import Column from './Column';

type Props = {
  categories: CategoryObj[],
};

const CategoriesList = ({ categories }: Props) => (
  <div className="row">
    {categories.map((category) => (
      <Column key={category.id}>
        <CategoryListItem category={category} />
      </Column>
    ))}
  </div>
);

export default CategoriesList;
