/**
 * Renders a single Category item
 *
 * This component also renders a hidden list of items in the category
 * which can be viewed by clicking on the category box.
 *
 * @flow
 */
import React, { useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import AnimateHeight from 'react-animate-height';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import useOnWindowResize from '@rooks/use-on-window-resize';

import type { CategoryObj } from './types';
import { TextButton, Container } from '../../styles';
import withActiveCategory from './withActiveCategory';
import DirectoryList from './DirectoryList';

const StyledImg = styled(Img)`
  height: 260px;
  border-radius: 4px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const Category = styled(TextButton)`
  && { /* increases specificity */
    position: relative;
    display: block;
    width: 100%;
    height: 260px;
    background: #000;
    color: #fff;
    line-height: inherit;
    border: 0 !important;
    border-radius: 4px;
    transition: margin-bottom 0.2s ease;
  }
`;

const CategoryContent = styled.span` /* div is not allowed inside a button */
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 119px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
  border-radius: 4px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.61) 0%, transparent 100%);

  span:first-of-type {
    font-size: 17px;
    font-weight: 500;
  }

  @media (min-width: 768px) {
    font-size: 17px;

    span:first-of-type {
      font-size: 21px;
      font-weight: normal;
    }
  }
`;

const CategoryItems = styled.div`
  position: absolute;
  top: 100%;
  left: -9999999em;
  z-index: 1;
  width: 100vw;
  margin-top: 50px;
  padding: 2.6em 0;
  background: #d8e9f3;
  box-shadow: inset 0 10px 13px -10px rgba(0, 0, 0, 0.08);
`;

const Triangle = styled.span`
  --size: 28px;
  position: absolute;
  top: calc(-1 * (var(--size) / 2));
  left: 50%;
  z-index: 2;
  display: block;
  width: var(--size);
  height: var(--size);
  transform: translateX(-50%) rotate(-45deg);
  transform-origin: center;
  background: #d8e9f3;
  pointer-events: none;
  box-shadow: inset 0 10px 13px -10px rgba(0, 0, 0, 0.08),
              inset -10px 0 13px -10px rgba(0, 0, 0, 0.08);
`;

const CategoryItemsContainer = styled(Container)`
  position: relative;
`;

const Breadcrumb = styled.h3`
  display: flex;
  flex-flow: wrap;
  margin-right: 1.2em; /* space for close button */
`;

const BreadcrumbItem = styled.span`
  display: block;

  &:not(:first-child) {
    margin-left: 0.45em;
  }

  &:not(:last-child) {
    color: #8e8e8e;
    margin-bottom: 0.25em;

    &::after {
      content: '';
      display: inline-block;
      width: 0.40em;
      height: 0.40em;
      margin-left: 0.45em;
      border-style: solid;
      border-width: 2px 2px 0 0;
      transform: rotate(45deg);
      transform-origin: top;
    }
  }
`;

const CloseButton = styled(TextButton)`
  position: absolute;
  top: 0.2em;
  right: var(--container-gutter-width);

  && {
    font-size: var(--font-size-h2);
  }
`;

type Props = {
  category: CategoryObj,
  className?: string,
  activeCategory: string,
  setActiveCategory: (id: string) => void,
};

const defaultProps = {
  className: '',
};

const CategoryListItem = ({
  category,
  className,
  activeCategory,
  setActiveCategory,
}: Props) => {
  const isActive = activeCategory === category.slug;

  const categoryRef = useRef<?HTMLButtonElement>();
  const categoryItemsRef = useRef<?HTMLDivElement>();

  const [activeCategoryStyles, setActiveCategoryStyles] = useState<any>();
  const [categoryItemsStyles, setCategoryItemsStyles] = useState<any>();
  const [activeTriangleStyles, setActiveTriangleStyles] = useState<any>();
  const [windowWidth, setWindowWidth] = useState<number>(0);

  useOnWindowResize(() => {
    setWindowWidth(window.innerWidth);
  });

  useLayoutEffect(() => {
    if (!isActive) {
      return;
    }

    if (categoryItemsRef.current) {
      setActiveCategoryStyles({
        marginBottom: categoryItemsRef.current.clientHeight + 100,
      });
    }

    if (categoryRef.current) {
      const categoryEl = categoryRef.current;
      setCategoryItemsStyles({
        left: -categoryEl.getBoundingClientRect().left,
      });
      setActiveTriangleStyles({
        left: categoryEl.getBoundingClientRect().left + (categoryEl.clientWidth / 2),
      });
    }
  }, [activeCategory, windowWidth]);

  function handleClick() {
    if (isActive) {
      setActiveCategory('');
    } else {
      setActiveCategory(category.slug);
    }
  }

  function handleClose() {
    setActiveCategory('');
  }

  const imgMarkup = category.acf
      && category.acf.featuredImage
      && category.acf.featuredImage.localFile
    ? (
      <StyledImg
        fluid={category.acf.featuredImage.localFile.childImageSharp.fluid}
        alt={category.acf.featuredImage.alt}
        loading="eager"
      />
    ) : null;

  return (
    <Wrapper className={className}>
      <Category
        onClick={handleClick}
        ref={categoryRef}
        style={isActive ? activeCategoryStyles : null}
      >
        {imgMarkup}
        <CategoryContent>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: category.name }} />
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: category.description }} />
        </CategoryContent>
      </Category>
      <AnimateHeight
        duration={200}
        animateOpacity
        height={isActive ? 'auto' : 0}
      >
        <CategoryItems
          ref={categoryItemsRef}
          style={isActive ? categoryItemsStyles : null}
        >
          <Triangle style={isActive ? activeTriangleStyles : null} />
          <CategoryItemsContainer>
            <Breadcrumb className="h2">
              <BreadcrumbItem>Categories</BreadcrumbItem>
              <BreadcrumbItem dangerouslySetInnerHTML={{ __html: category.name }} />
            </Breadcrumb>
            <CloseButton onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
            <DirectoryList items={category.items} />
          </CategoryItemsContainer>
        </CategoryItems>
      </AnimateHeight>
    </Wrapper>
  );
};

CategoryListItem.defaultProps = defaultProps;

export default withActiveCategory(CategoryListItem);
