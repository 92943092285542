/**
 * Renders search results (both categories and items)
 *
 * @flow
 */
import React from 'react';
import styled from 'styled-components';

import type { SearchResultsObj } from './types';
import CategoriesList from './CategoriesList';
import DirectoryList from './DirectoryList';

const ResultGroup = styled.div`
  & + & {
    margin-top: 10px;

    @media (min-width: 768px) {
      margin-top: 15px;
    }

    @media (min-width: 992px) {
      margin-top: 30px;
    }
  }
`;

type Props = {
  directoryName: string,
  searchResults: SearchResultsObj,
}

const SearchResults = ({ searchResults, directoryName }: Props) => (
  <>
    {searchResults.categories.length > 0 && (
      <ResultGroup>
        <h2>Categories</h2>
        <CategoriesList categories={searchResults.categories} />
      </ResultGroup>
    )}
    {searchResults.items.length > 0 && (
      <ResultGroup>
        <h2>{directoryName}</h2>
        <DirectoryList items={searchResults.items} />
      </ResultGroup>
    )}
  </>
);

export default SearchResults;
