/**
 * Renders bootstrap grid column for both category & items list
 *
 * @flow
 */
import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const bootstrapColumnClass = 'col-sm-6 col-md-4 col-lg-3';

const Wrapper = styled.div`
  @media (min-width: 414px) and (max-width: 575px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 1200px) {
    && { /* increases specificity */
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
`;

type Props = {
  children: React.Node,
  className?: string,
};

const defaultProps = {
  className: undefined,
};

const Column = ({
  children,
  className,
}: Props) => (
  <Wrapper className={classnames(bootstrapColumnClass, className)}>
    {children}
  </Wrapper>
);

Column.defaultProps = defaultProps;

export default Column;
