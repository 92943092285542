// @flow
import * as React from 'react';

export const ActiveCategoryContext = React.createContext<any>({});

export default function withActiveCategory(Component: React.ComponentType<any>) {
  function ComponentWithActiveCategory(props: any) {
    return (
      <ActiveCategoryContext.Consumer>
        {(context) => (
          <Component
            // It's an HOC, we don't really know the props in advance
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            activeCategory={context.activeCategory}
            setActiveCategory={context.setActiveCategory}
          />
        )}
      </ActiveCategoryContext.Consumer>
    );
  }

  return ComponentWithActiveCategory;
}
